<template>
  <div class="flex flex-wrap items-start m-3">
    <div
      v-for="(order, index) in orders"
      :id="'id' + index"
      :key="order.number"
      class="xl:w-80 lg:w-72 md:w-80 sm:w-72 order-wrapper"
      @click="notifyOrderSelection(order, index)"
    >
      <div
        class="leading-relaxed cursor-pointer flex justify-between m-1 text-white px-3 py-1 rounded-xl"
        :class="{
          'bg-red-450': order.status == 'out-of-sla',
          'bg-green-450': order.status == 'in-sla',
        }"
      >
        <p class="textWrapper">
          <span class="font-bold block">{{ order?.kitchen_picker?.name }}</span>
          <span class="text-sm date"> {{ order.time }}</span>
          <span class="font-normal block">{{ order.customer_group !== 'Normal' ? order.customer_group : '' }}</span>
        </p>
        <p class="text-left">
          <span class="font-bold number block">{{ order.order_number }}</span>
          <span class="text-sm">{{ order.items.length }} منتجات</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
    currentOrderIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ['orderSelection'],
  data: () => {
    return {
      previousId: '',
      currentId: '',
    };
  },

  methods: {
    notifyOrderSelection(order, index) {
      this.previousId = this.currentId;
      if (this.previousId) document.querySelector(this.previousId).classList.remove('selected-order-wrapper');
      this.currentId = '#id' + index;
      document.querySelector(this.currentId).classList.add('selected-order-wrapper');
      this.$emit('orderSelection', { order: order, orderIndex: index });
    },

    selectThePulledOrder(index) {
      setTimeout(() => {
        this.previousId = this.currentId;
        if (this.previousId) document.querySelector(this.previousId).classList.remove('selected-order-wrapper');
        this.currentId = '#id' + index;
        document.querySelector(this.currentId).classList.add('selected-order-wrapper');
      }, 1000);
    },
  },
};
</script>

<style>
.order-wrapper {
  border: 5px solid #e5e5e5;
}

.selected-order-wrapper {
  border: 5px solid #84c559;
  border-radius: 20px;
}
.textWrapper {
  flex-grow: 1;
}
</style>
